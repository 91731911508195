.pagination_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px;
}
.MuiPagination-root button {
  background: #f6f6f6;
  border-radius: 6px;
}

.MuiPagination-root .Mui-selected {
  color: white;
  background-color: var(--primary) !important;
}
