.useImage {
    
    // content wrapper
    .content-wrapper {
        display: flex;
        justify-content: center;
    }

    // text wrapper
    .text-wrapper {
        h1 {
            margin: 5px 0px;
        }

        p {
            margin-bottom: 25px;
        }
    }

    .info-size{
        font-weight: 400;
        font-size: 16px;
        color: var(--dark);
    }
    .use-image-generate-button {
        display: flex;
        justify-content: flex-end;
    }

    .swiperSlider {
        margin-top: 20px;
        padding-left: 20px !important;
        padding-right: 20px !important;
        overflow: visible !important;
    }

    .swiper-slider-wrapper {
        width: 100%;
        margin-left: 40%;
        .swiper-button-next,
        .swiper-button-prev {
            color: var(--primary) !important;
            fill: var(--primary) !important;
            stroke: var(--primary) !important;
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
            font-size: 1rem;
            font-weight: 600;
        }

        .swiper-button-prev {
            left: -20px;
        }

        .swiper-button-next {
            right: -20px;
        }
    }

    .swiperSlider-item {
        position: relative !important;

        .swiperSlider-image {
            border-radius: 6px;
            width: 100%;
            height: auto;
            object-fit: cover;
            display: block;
            position: relative;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        }
    }

    .swiper-slider-close-icon {
        position: absolute;
        right: 5px;
        top: 5px;
        border-radius: 20px;
        border: 1px solid #bfbfbf;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
