@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import "varibale";
@import "globalClass";
@import "button.scss";
@import "signLog.scss";
body {
  background: var(--bg-color);
  font-family: "Montserrat";
  font-weight: 400;
  height: 100%;
  color: var(--secondary);
  font-size: 0.875rem;
  position: relative;
  box-sizing: border-box;
}
* {
  font-family: "Montserrat" !important;
}
::placeholder {
  letter-spacing: -0.01em;
  line-height: 14px;
}
h1 {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  color: var(--dark);
}
h2 {
  font-size: 17px;
  font-weight: bold;
  line-height: 16px;
  margin: 0;
  color: var(--dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--dark);
}
ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
a {
  text-decoration: none;
  &:hover {
    color: var(--primary);
  }
}
p {
  color: var(--secondary);
  line-height: 16px;
  letter-spacing: -0.01em;
  font-size: 0.8rem;
}
textarea,
select {
  border: 1.5px solid var(--bg-color) !important;
  border-radius: 6px;
  padding: 0.5rem 0.75rem !important ;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px var(--bg-slider-yellow) !important;
    // box-shadow: 0px 0px 0px 2px rgba(237, 86, 131, 0.2) !important;
  }
}

table {
  border: 1px solid var(--border-color);
  border-radius: 6px !important;
  thead {
    background-color: var(--bg-color);
    tr {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1rem;
      color: var(--light);
    }
  }
  td,
  th {
    font-size: 14px;
    vertical-align: middle;
    padding: 1rem 0.75rem !important;
    p {
      margin: 0;
    }
  }
  .table_processing {
    i {
      font-size: 1rem !important;
      color: #f3994f !important;
    }
  }
  .no-data {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    color: #8a8a8a;
    letter-spacing: -0.01em;
  }
}
