.category_details_container {
  margin: 28px 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.category_detail_wrapper {
  width: 650px;
  text-align: left;
}
.back_btn {
  cursor: pointer;
}
.detail_header {
  display: flex;
  align-items: center;
}
.detail_header_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #3b3c41;
  margin-left: 12px;
}
.detail_header_txt {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8a8a8a;
  text-align: left;
  margin-top: 6px;
}
.info_text {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8a8a8a;
  margin-left: 10px;
}
.info_container {
  display: flex;
  align-items: center;
}
.img_carousel_container {
  margin-top: 26px;
}
.img_preview {
  width: 100%;
  height: 300px;
  margin-top: 14px;
  object-fit: cover;
}
.carousel_img {
  width: 100%;
  height: 100%;
  border: 1.5px solid #ed5683;
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
}

.swiperSlider {
  margin-top: 20px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  overflow: visible !important;
}

.swiper-slider-wrapper {
  width: 100%;
  margin-left: 40%;
  .swiper-button-next,
  .swiper-button-prev {
      color: var(--primary) !important;
      fill: var(--primary) !important;
      stroke: var(--primary) !important;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
      font-size: 1rem;
      font-weight: 600;
  }

  .swiper-button-prev {
      left: -20px;
  }

  .swiper-button-next {
      right: -20px;
  }
}

.swiperSlider-item {
  position: relative !important;

  .swiperSlider-image {
      border-radius: 6px;
      width: 100%;
      height: auto;
      object-fit: cover;
      display: block;
      position: relative;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
}

.swiper-slider-close-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 20px;
  border: 1px solid #bfbfbf;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_carousel_wrapper {
  padding: 12px;
}
.image_wizard_container {
  margin-top: 32px;
}

.image_size {
  margin-top: 30px;
  font-family: "Montserrat";
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #121212;
  }
  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #8a8a8a;
    margin-top: 7px;
  }
  .size_selector {
    margin-top: 10px;
    .label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #121212;
    }
    .input_value {
      margin-left: 32px;
      padding: 5px 12px;
      background: #ffffff;
      border: 1.5px solid #ebebeb;
      border-radius: 6px;
      width: 70%;
    }
  }
  .fit_to_select{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #121212;
    }
    .select_value {
      margin-left: 32px;
      padding: 5px 12px;
      background: #ffffff;
      border: 1.5px solid #ebebeb;
      border-radius: 6px;
      width: 87%;
      height: 34px;
    }
  }
}

.quantity_slider{
  margin-top: 30px;
  font-family: "Montserrat";
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #121212;
  }
  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #8a8a8a;
    margin-top: 7px;
  }
}

.generator{
  margin-top: 60px;
  text-align: right;
}