// Category Card
.category-card {
  padding: 0.75rem;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
  position: relative;
  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    transform: translateY(-3px);
  }
  .card-image img {
    border-radius: 8px;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .edit_fill_img {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .private_label {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;

    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    top: 230px;
    right: 17px;
  }
  .category-card_content {
    margin-top: 1rem;
    h2 {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: -0.01em;
    }
  }
}
