.generate-card-wrapper {
    position: relative;
    // generated image card
    .generate-image-card {
        margin-top: 25px;
        cursor: pointer;
        // img tag
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    // generated image download icon
    .generate-download-icon {
        // display: none;
        position: absolute;
        bottom: 22px;
        right: 29px;
        opacity: 0.8;
    }

      .saveImage-image{
        position: relative;
        :hover + .button-section{
                opacity: 1;
                transition-duration: 0.09s;
                transition-timing-function: ease-in;
                transition-delay: 0.1s;
            }
        
        .button-section{
            position: absolute;
            bottom: 0.875rem;
            right: 0.875rem;
            opacity: 0;
        }

        button{
            background: var(--bg-color);
            border-radius: 6px;
            border: 0px;
            padding: 8px 10px;
            margin-left: 8px;
            opacity: 0.9
        }:hover{
        opacity: 1
        }
    }

}
// generated image download icon show on hover
.generate-card-wrapper:hover .generate-download-icon {
    // display: block;
    // cursor: pointer;
    opacity: 1;
}
