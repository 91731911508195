.image_upload_container {
  margin-top: 30px;
  font-family: "Montserrat";

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #121212;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #8a8a8a;
    margin-top: 7px;
  }

  .upload_btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    width: 120px;
    height: 36px;
    background: #f6f6f6;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    margin-top: 12px;
    cursor: pointer;

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #3b3c41;
    }
  }

  .upload_btn:hover {
    background: #f3f3f3;
  }

  .uploaded_images {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;

    .img {
      position: relative;

      .close_img_container {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid gray;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 6px;
        right: 14px;
        cursor: pointer;

        .close_img {
          height: 7px;
        }
      }
    }
  }

  .no_of_images {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #3B3C41;
    margin: 5px 0;
  }
}