.subjectWizard_wrapper {
  background-color: var(--white);
  border-radius: 12px;
  scroll-behavior: auto;
  .sliderGrid-item {
    height: 100% !important;
    text-align: center;
  }
  .swiper-backface-hidden .swiper-slide {
    aspect-ratio: 1 / 1 !important;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  .subjectWizard {
    background-color: var(--white);
    padding: 1.5rem 2.5rem;
    @media screen and (max-width: 600px) {
      padding: 1rem;
    }

    .subjecWizard_slider {
      border: 1.5px solid #ebebeb;
      padding: 0.75rem;
      width: 100%;
      position: relative;
      .swiper {
        z-index: 1;
        position: initial;
      }
      .swiper-button-next,
      .swiper-button-prev {
        padding: 10px;
        color: var(--primary) !important;
        fill: var(--primary) !important;
        stroke: var(--primary) !important;
        z-index: 10 !important;
      }
      .swiper-button-next:after,
      .swiper-button-prev:after {
        font-size: 1rem;
        font-weight: 600;
      }
      .swiper-button-prev {
        left: -25px;
      }
      .swiper-button-next {
        right: -25px;
      }
    }
  }
  .sliderGrid-item {
    position: relative !important;
    .item-image {
      border-radius: 6px;
      width: 100%;
      object-fit: cover;
    }
    .item-check {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .item-title {
      position: absolute;
      display: block !important;
      margin: auto;
      padding: 0 3px;
      border-radius: 5px;
      background-color: black;
      left: 0;
      right: 0;
      bottom: 10px;
      align-items: center;
      justify-content: center;
      color: var(--white);
    }
  }

  .container-dots {
    display: flex;
  }
  .dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: var(--bg-color);
    margin: 0 3px;
  }
  .dot.active {
    background: var(--primary);
  }
}
