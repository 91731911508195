::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
* {
  scroll-behavior: smooth;
  user-select: none;
  font-size: 14px;
}
.popup {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  .popup_wrapper {
    margin: 1% auto;
    padding: 20px;
    width: 100%;
  }
}
.category_filter_container{
  .search-input,
  p,
  div
  {
    font-size: 14px;
  }
}
.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list li {
  font-size: 14px;
}
.dashboard-heading-dropdown {
  input, select {
    font-size: 14px;
  }
}
.modal-dialog {
  margin: auto;
 
  .modal-content {
   
    border-color: transparent;
    align-items: center;
    background-color: transparent !important;
    .modal_wrapper {
      background-color: var(--white);
      border-radius: 8px;
    }
  }
}

.carousel-control-next-icon{
  background-image: url("../assets/Icons/CarouselRight.svg");
}

.carousel-control-prev-icon{
  background-image: url("../assets/Icons/CarouselLeft.svg");
}

.carousel-indicators{
  visibility: hidden;
}



.tabs {
  border-bottom: 1px solid var(--bg-color);
  display: flex;
  flex-wrap: wrap;
  .tab-item {
    background: none;
    border: none;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: var(--secondary);
    display: flex;
    align-items: center;
    flex-wrap: nowrap !important;
    padding-bottom: 0.5rem;
    transition: all 0.3s linear;
    @media screen and (min-width: 600px) {
      margin-left: 2rem;
    }
    span {
      visibility: hidden;
    }
    .dot {
      visibility: visible;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #f3994f;
      margin-left: 10px;
    }
  }
  .tab-item:first-child {
    margin-left: 1.5rem;
  }
  .tab-item.active {
    font-weight: 600;
    border-bottom: 2px solid var(--primary);
    color: var(--light);
    position: relative;
    
  }
}
.tab-content_wrapper {
  margin: 2rem;
  @media screen and (max-width: 600px) {
    margin: 2rem 0;
    .content-title {
      margin-bottom: 1rem;
    }
  }
}
.category_filter_container .content-title {
  margin-bottom: 0;
}
.content-title {
  margin: 10px 0 !important;
  cursor: pointer;
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: var(--dark);
  }
  span {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 12px;
    letter-spacing: -0.01em;
    color: var(--secondary);
  }
}
.content-wrapper {
  margin: 1rem 0;
}
.form-title {
  h4 {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: var(--dark);
    margin-bottom: 0.4rem !important;
  }
}
.sub-title {
  margin-bottom: 1.5rem;
  color: var(--secondary);
  font-weight: 400;
  font-size: 0.75rem !important;
}

hr {
  background-color: var(--bg-color);
  margin: 2rem 0 !important;
}

.form-check-input {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}
.form-group {
  width: 100%;
  position: relative;
  .sliderValue {
    position: relative;
    // width: 100%;
    span {
      position: absolute;
      bottom: -30px;
      font-weight: 500;
      z-index: 2;
    }
  }
  .field {
    position: relative;
    width: 100%;
    .value {
      position: absolute;
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--dark);
    }
    .value.left {
      left: 0px;
    }
    .value.right {
      right: 0;
    }
    .range-slider__wrap {
      width: 90%;
      position: relative !important;
    }
    .range-slider__tooltip__label {
      font-size: 0.75rem;
      background-color: var(--primary);
    }
    .range-slider__tooltip__caret {
      &::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: var(--primary) !important;
      }
    }
    .range::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      border-radius: 10px;
      background: var(--primary) !important;
      overflow: visible;
      cursor: pointer;
      &:focus {
        box-shadow: none !important;
      }
    }
    .range::-webkit-slider-runnable-track {
      background-color: var(--bg-color);
    }
  }
}
.form-label {
  color: var(--dark);
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  width: 50%;
  display: block;
  @media screen and (max-width: 991px) {
    margin-bottom: 0.75rem;
  }
}
.progress-container {
  width: 40px;
  height: 40px;
}
input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px var(--bg-slider-yellow) !important;
}
.form-control {
  border: 1.5px solid var(--bg-color) !important;
  border-radius: 6px;
  font-size: 14px;
  padding: 0.5rem 0.75rem !important ;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px var(--bg-slider-yellow) !important;
  }
}
.border-bg {
  border: 1.5px solid var(--bg-color);
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--primary);
  border-radius: 5px;
  border-color: transparent;
}

.page-link {
  z-index: 3;
  color: var(--dark);
  background-color: var(--bg-color-light);
  border-radius: 5px;
  margin-left: 10px;
  width: 32px;
  text-align: center;
}

.page-link:hover {
  z-index: 3;
  color: var(--dark);

  border-radius: 5px;
  margin-left: 10px;
  width: 32px;
  text-align: center;
}

.page-item:not(:first-child) .page-link {
  margin-left: 4px;
}

.toast {
  position: relative;
  background-color: white;
  z-index: 99;
  padding: 15px;
  width: 422px;
}
.error {
  color: var(--primary);
  font-size: 0.75rem;
  margin-top: 5px;
}
