.top__nav {
    position: sticky;
    top: 0;
    z-index: 99;
    background: white;
    // @media screen and (max-width: 991px) {
    //     width: 93%;
    //     // width: 90%;
    // }
    // @media screen and (max-width: 767px) {
    //     width: 100%;
    //     // padding: 1rem 4.5rem;
    // }
    height: 70px;
    line-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .userAvatar {
        padding: 8px;
        border-radius: 50%;
        background-color: var(--primary);
    }
    .profile {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0px 30px;
        img {
            width: 20px;
            height: 20px;
        }
        h6 {
            margin-bottom: 0px !important;
        }

        .btn-secondary{
            background-color: var(--bg-color-light);
            color: var(--light);
            opacity: 1;
            padding: 0.5rem 1rem;
            margin: 0rem 1.5rem;
            font-weight: 600;
            font-size: 14px;
        }:hover{
            opacity: 0.8;
        }
        span{
            font-weight: 400;
            font-size: 14px;
        }
        .logout_container {
            position: relative;
            #logout {
                position: absolute;
                top: 55px;
                right: -50px;
                display: none;
                width: 100px;
            }
        }:hover {
            #logout {
                display: block;
            }
        }
    }
    .pageNavigation__wrapper {
        display: flex;
        align-items: center;
        margin-left: 35px;
        cursor: pointer;
        h6 {
            font-weight: 700;
            text-transform: capitalize;
            margin-bottom: 0px !important;
        }
        i {
            font-size: 1.6rem;
            color: var(--dark);
        }
    }
}
