.TypePrompt_settings-wrapper {
    padding: 1rem 1.5rem;
    @media screen and (min-width: 767px) {
        padding: 1rem 4.5rem;
    }
    @media screen and (max-width: 600px) {
        padding: 0.75rem;
    }
}

.subjectWizard_wrapper {
    background-color: var(--white);
    border-radius: 12px;
    scroll-behavior: auto;
    
    .btn-ct{
        margin-right: 2.5rem;
    }
    // overflow: hidden;
    .subjectWizard {
        background-color: var(--white);
        padding: 1.5rem 2.5rem;
        @media screen and (max-width: 600px) {
            padding: 1rem;
        }

        .form-title{
            border-radius: 6px 6px 0px 0px;
        }
        
        .title-selector{
            padding: 0.75rem 1.5rem;
            border-radius: 6px 6px 0px 0px;
            h4{
                font-size: 1.25rem;
                font-weight: 600;
            }
        }

        .subjecWizard_slider {
            border: 1.5px solid #ebebeb;
            padding: 0.75rem 1.5rem;
            width: 100%;
            position: relative;
            border-radius: 0px 0px 6px 6px ;
            // overflow: hidden;
            // z-index: 2;

            .selected-radio-btn{
                border: 2px solid var(--primary);
                border-radius: 6px;
            }

            .swiper {
                // overflow: visible !important;
                z-index: 1;
                position: initial;
            }
            .swiper-button-next,
            .swiper-button-prev {
                padding: 10px;
                color: var(--primary) !important;
                fill: var(--primary) !important;
                stroke: var(--primary) !important;
                z-index: 10 !important;
            }
            .swiper-button-next:after,
            .swiper-button-prev:after {
                font-size: 1rem;
                font-weight: 600;
            }
            .swiper-button-prev {
                left: -25px;
            }
            .swiper-button-next {
                right: -25px;
            }
        }
    }
    .sliderGrid-item {
        position: relative !important;
        .item-image {
            border-radius: 6px;
            width: 100%;
            object-fit: cover;
        }
        .item-check {
            position: absolute;
            top: 10px;
            right: 10px;

            
        }
        .item-title {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--white);
        }
    }
    .container-dots {
        display: flex;
        margin-left: 2.5rem;
    }
    .dot {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background-color: var(--primary);
        margin: 0 3px;
        -webkit-flex-shrink: 0;
		        -ms-flex-negative: 0;
		            flex-shrink: 0;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }
    .dot.active {
        background: var(--primary);
        width: 1.875rem;
        height: 0.75rem;
        border-radius: 20px;
    }
}

.generated-alert{
    width: 400px;
    height: 200px;
    right: 7em;
}

.pending-alert{
    width: 400px;
    height: 108px;
    right: 7em;
}

.pending-info{
    margin-left: 1.025rem;
    h2{
        font-weight: 700;
        font-size: 16px;
        color: #121212;
        margin-bottom: 1rem;
    }
    span{
        font-weight: 400;
        font-size: 14px;
        color: #8A8A8A;
    }
}