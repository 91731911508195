.preset-confirm-modal {
    z-index: 9999999 !important;
    background-color: rgba(0,0,0,0.7);
}
.mod {
    width: 27.5rem
}

.modal_wrapper {
    .modal-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1.3rem;

        .content-title {
            margin-bottom: 0;

            h3 {
                margin: 0;
            }
        }
    }


}

// edit name popup input field and text
.edit-popup-body {
    input {
        box-sizing: border-box;
        padding: 5px 12px;
        width: 100%;
        height: 40px;
        border: 1px solid var(--bg-color);
        // box-shadow: 0px 0px 0px 2px rgba(237, 86, 131, 0.2);
        border-radius: 6px;
        margin-bottom: 1rem;
    }

    .input_error {
        border: 1px solid var(--primary);
    }

    p {
        color: var(--primary);
        margin-bottom: 20px;
    }
}
