.saveImage-card-wrapper {
  position: relative;
  height: 90%;
  // saveImage image card
  .saveImage-image-card {
    margin-top: 25px;
    cursor: pointer;
    height: 100%;
    .saveImage-image {
      height: 100%;
    }
    // img tag
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .imgTitle {
      margin-top: 0.625rem;
      text-align: center;
      color: var(--dark);
      font-size: 0.875rem;
      font-weight: 600;
    }
    .imgDate {
      margin-top: 0.625rem;
      font-weight: 400;
      font-size: 0.875rem;
      text-align: center;
      color: var(--secondary);
    }
  }

  .saveImage-image{
    position: relative;

    .button-section{
        position: absolute;
        bottom: 0.875rem;
        right: 0.875rem;
    }

    button{
        background: var(--bg-color);
        border-radius: 6px;
        border: 0px;
        padding: 8px 10px;
        margin-left: 8px;
        opacity: 0.9
    }:hover{
      opacity: 1
    }
}
}
  // // saveImagedimage download icon
  // .saveImage-download-icon {
  //   // display: none;
  //   position: absolute;
  //   bottom: 22px;
  //   right: 47px;
  //   opacity: 0.8;
  // }
  // .saveImage-edit-icon {
  //   // display: none;
  //   position: absolute;
  //   bottom: 22px;
  //   right: 10px;
  //   opacity: 0.8;
  // }
// }

// saveImage image download icon show on hover
// .saveImage-card-wrapper:hover .saveImage-download-icon {
//   // display: block;
//   // cursor: pointer;
//   opacity: 1;
// }

// .image__view {
//   overflow: hidden;

//   img {
//     width: 100%;
//     object-fit: contain;
//   }

//   .mainImg {
//     height: 600px;
//   }

//   .image__view-wrapper {
//     position: relative;
//     overflow: hidden;

//     .popup_toogleButtons {
//       position: absolute;
//       right: 32px;
//       top: 12px;
//       z-index: 999;
//     }
//   }
// }


.content-title{
  .search-input{
      border: 0px;
      width: 21.125rem;
  }

  :focus {
      outline: none;
  }
}

