
  .modal-dialog {
    margin: auto;
    width: 80%;
    max-width: 70% !important;
    @media screen and (max-width: 991px) {
      width: 90%;
    }
  }

