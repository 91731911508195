.footer-container {
    background-color: white;
    height: 50px;
    border-radius: 5px;
    margin: 0 0.75rem;
    padding: 10px 20px;
    @media screen and (max-width: 767px) {
        width: calc(100% - 70px);
    }
    img {
        width: 30px;
        height: 30px;
    }
    .footer-buttons {
        a {
            display: inline-block;
            margin: 0 8px;
            color: black;
        }
    }
}