// Summary card
.SummaryCard {
    width: 100%;
    border: 1.5px solid var(--border-color);
    border-radius: 12px;
    max-height: 127px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 6px;
    background: var(--white);
}

.SummaryContentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    // Summary card counter value
    h2 {
        margin: 0 8px 0 0;
        min-width: 47px;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.01em;
    }
}

// Summary card content
.summaryCardContent {
    display: flex;
    flex-direction: row;
    align-items: center;

    // Summary card counter percentage
    p {
        margin-left: 8px;
    }
}

.SummaryCardIcon {
    width: 24px;
    height: auto;
}

// Summary card heading
.SummaryCardText {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    color: var(--secondary);
}
