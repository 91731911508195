.layout {
    .main__layout {
        // padding: 30px;
        border: 1px solid #ebebeb;
        width: 100%;
        // overflow: hidden !important;
        @media screen and (max-width: 600px) {
            margin-left: 70px;
        }
    }
    .main__layout-wrapper {
        // margin-top: 6rem;
        .content {
             height: calc(100vh - 155px) !important;
             position: relative;
            //height: calc(100vh - 165px) !important;
            overflow-y: auto;
            border-radius: 8px;
            padding: 32px;
            background-color: var(--white);
            height: 100%;
            margin: 0.75rem;
            @media screen and (max-width: 600px) {
                padding: 0px;
            }
        }
    }

        .container-fluid{
            padding: 0px;
        }
}
