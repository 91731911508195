.nav-modal{
    background-color: transparent;
    .modal-dialog{
        background-color: transparent;

        justify-content: center;
         .modal-content {
            
            padding: 4rem;
            align-items: center;
            width: 37.5rem;
            background-color: white !important;
            border-color: transparent;

        }
        .modal-body{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0px;
            img{
                width: 48px;
                height: 48px;
            }
            h2{
                margin: 1.5rem 0rem;
                font-weight: 700;
                font-size: 24px;
            }
            span{
                font-weight: 400;
                font-size: 14px;
                color: var(--light);
                line-height: 14px;
                a{
                    color: var(--primary);
                }
            }

            .btn-primary{
                margin-top: 1.625rem;
            }
        }
    }
}