.size-section{
    label{
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: var(--dark);
        display: inline;
        width: 60px;
    }

}