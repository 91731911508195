.create_custom_category {
  margin-left: 24px;
}

.sub_category_tab {
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #8a8a8a;
}

.sub_category_tab:hover {
  color: #3b3c41;
}

.sub_category_active {
  color: #3b3c41;
}

.choose_category_label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: #8a8a8a;
}

.category_filter_container {
  margin: 0px 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .form-control {
    display: flex;
  }
  .content-title {
    width: 60%;
  }
}

.filter_select {
  width: 250px;
}

.edit_fill_img {
  position: absolute;
  top: 9px;
  right: 21px;
  cursor: pointer;
}
.category_card_container{
  position: relative;
}