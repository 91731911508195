.modalContainer {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  .rightSide {
    padding: 1rem 2rem;
    .close-modal {
      cursor: pointer;
    }
  }
  .leftSide {
    height: 100% !important;
    .carousel {
      height: 100% !important;
      .carousel-inner {
        height: 100% !important;
        .carousel-item {
          height: 100% !important;
          .carouselPagination {
            background: #8a8a8a;
            border-radius: 20px;
            width: 62px;
            height: 30px;
            text-align: center;
            color: white;
            position: absolute;
            margin-left: 80%;
            top: 5%;
            padding: 1%;
            padding-bottom: 2%;
          }
          .modalImg {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
      }
      .carousel-indicators {
        display: none;
      }
    }
  }
  .range {
    width: 90%;
  }
  @media screen and (max-width: 991px) {
    margin: 2rem 0;
  }
}
