.profileImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
}
.profileGeneral {
    margin: 1.5rem 4.375rem;

    .sub-title{
        font-size: 1rem !important;
    }
    // border: 1px solid #EBEBEB;
    .profileGeneral-inner {
        color: var(--dark);
    }
}
.imageStatistic {
    margin: 1.5rem 4.375rem;
    .sub-title{
        font-size: 1rem !important;
    }
    .imageStatistic-inner {
        text-align: center;
        .imageStatistic-card {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: var(--bg-color);
            border: 1px solid var(--bg-color);
            border-radius: 8px;
            padding: 8px;
            color: var(--dark);
            span:nth-child(2) {
                font-weight: 600;
            }
        }
    }
}
