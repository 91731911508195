.custom_category_container {
    margin: 28px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";

    .wrapper {
        width: 650px;
        text-align: left;

        .header {
            .title {
                font-style: normal;
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                color: #121212;
            }

            .description {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #8A8A8A;
                margin-top: 6px;
            }
        }

        .name_input {
            margin-top: 24px;

            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                color: #121212;
                margin-bottom: 10px;
            }

            .input {
                flex-direction: row;
                align-items: center;
                padding: 5px 12px;
                background: #FFFFFF;
                border: 1.5px solid #EBEBEB;
                border-radius: 6px;
                width: 100%;
            }

            .description {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #8a8a8a;
                margin-top: -2px;
                margin-bottom: 10px;
            }
        }

        .generator {
            margin-top: 60px;
            text-align: right;
        }
    }
}