.dashboard-section{
  margin: 0rem 1.5rem;
}
.dashboard-heading-dropdown {
    margin-bottom: 20px;
    .content-title {
        margin: 0px;
    }

}

.dashboard-timeframe-dropdown {
    width: 255px;
    position:relative;
}
.dashboard-timeframe-dropdown label {
    font-size:14px;
    text-transform: capitalize;
    // color: #777;
    padding: 0 8px;  
    position: absolute;
    // top:6px;
}

.dashboard-datepicker:first-child {
  margin-right: 10px !important;
}

.dashboard-datepicker {
  margin-bottom: 10px !important;
}

.dashboard-datepicker input {
  padding: 10px;
}

.form-select[name="timeFrame"] {
  margin-bottom: 10px;
}

.select-wrap {
    border: 1px solid #777;
    border-radius: 4px;
    padding: 0 20px;
    width:300px;
    background-color:#fff;
    position:relative;
  }
  .select-wrap label{
    font-size:8px;
    text-transform: uppercase;
    color: #777;
    padding: 0 8px;  
    position: absolute;
    top:6px;
  }
  
  select{
    background-color: #fff;
    border:0px;
    font-size: 16px;
  }
