:root {
  --primary: #110041;
  --light: #3b3c41;
  --white: #fff;
  --black: #000000;
  --dark: #110041;
  --bg-color: #D3FEEF;
  --secondary: #8a8a8a;
  --border-color: #ebebeb;
  --bg-color-light: #f6f6f6;
  --bg-slider-yellow: #E4F614;
}

// $blue: #0a2748;
// $text: #6c8eb6;
