.popup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;

  .popup-button-section {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: row;
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
