.saveImages{
    margin: 0rem 1.5rem;
    .content-title {
        width: 255px;
        // width: calc(100% - 260px);
        // min-width: 250px;
        input {
            width: calc(100% - 22px) !important;
        }
    }
}
div.dashboard-heading-dropdown {
    @media screen and (max-width: 767px) {
        justify-content: center !important;
    }
}